import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import DataTable from 'components/common/DataTable'

import useClientCase from 'hooks/useClientCase'
import FormRenderer from 'components/common/FormRenderer'

import { format, parseISO } from 'date-fns'

function CaseBrowser() {
  const formikRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { clientCaseConstantsGet, clientCaseSearch } = useClientCase()
  const [clientCaseSearchParams, setClientCaseSearchParams] = useState({
    sort: { client_case_created_datetime: false },
  })
  const [clientCases, setClientCases] = useState([])
  const [clientCaseDisplay, setClientCaseDisplay] = useState([])
  const [clientCaseConstants, setClientCaseConstants] = useState({})
  const [hasMoreCases, setHasMoreCases] = useState(false)

  const searchLookup = async (payload, appendResults, setErrors) => {
    try {
      payload.limit = 100
      let res = await clientCaseSearch(payload)
      if (res?.client_cases && res.client_cases.length > 0) {
        if (appendResults) {
          setClientCases([...clientCases, ...res.client_cases])
        } else {
          setClientCases(res.client_cases)
        }
      } else if (!appendResults) {
        setClientCases([])
      }

      setHasMoreCases(
        res?.client_cases?.length > 0 &&
          res.client_cases.length === (res?.limit || 0)
      )
      payload.offset = res?.offset || 0
      payload.limit = res?.limit || null
      setClientCaseSearchParams(payload)
      return true
    } catch (err) {
      console.log(err)
      if (setErrors) {
        setErrors({
          submit: err.response?.data?.message ?? 'An error has occurred',
        })
      }
      return false
    }
  }

  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true)
    try {
      let payload = {}
      let valueKeys = Object.keys(values)
      for (let i in valueKeys) {
        if (values[valueKeys[i]]) {
          payload[valueKeys[i]] = values[valueKeys[i]]
        }
      }
      await searchLookup(payload, false, setErrors)
    } catch (err) {
      setErrors({
        submit: err.response?.data?.message ?? 'An error has occurred',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (Array.isArray(clientCases) && clientCases.length > 0) {
      let newDisplay = []
      for (let i = 0; i < clientCases.length; i++) {
        newDisplay.push({
          Assignee: clientCases[i].user_id ? (
            <Link to={'/console/users/' + clientCases[i].user_id}>
              {clientCases[i].user.user_first_name}{' '}
              {clientCases[i].user.user_last_name}
            </Link>
          ) : (
            'Unassigned'
          ),
          Customer: `${clientCases[i].customer.customer_first_name} ${clientCases[i].customer.customer_last_name}`,
          Timing: clientCases[i].client_case_timing,
          'Service Date': clientCases[i]?.client_case_service_datetime
            ? format(
                parseISO(clientCases[i].client_case_service_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Denial Date': clientCases[i]?.client_case_denied_datetime
            ? format(
                parseISO(clientCases[i].client_case_denied_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Due Date': clientCases[i]?.client_case_due_date
            ? format(parseISO(clientCases[i].client_case_due_date), 'M/d/y')
            : 'N/A',
          Status: clientCases[i].client_case_status,
          Created: format(
            parseISO(clientCases[i].client_case_created_datetime),
            'M/d/y h:mm a'
          ),
          Actions: (
            <Link to={`/console/case-detail/${clientCases[i].client_case_id}`}>
              <Button variant="contained">Manage</Button>
            </Link>
          ),
        })
      }
      setClientCaseDisplay(newDisplay)
    } else {
      setClientCaseDisplay([])
    }
  }, [clientCases])



  useEffect(() => {
    ;(async function fetchInitialData() {
      setLoading(true)
      try {
        let constantsResult = await clientCaseConstantsGet()
        setClientCaseConstants(constantsResult || {})
        await searchLookup(clientCaseSearchParams || {}, false, undefined)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getNextPage = async () => {
    if (hasMoreCases) {
      setLoading(true)
      try {
        let payload = {
          ...clientCaseSearchParams,
        }
        payload.offset = (payload.limit || 0) + (payload.offset || 0)
        await searchLookup(payload, true, undefined)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  let clientCaseTimings = []
  let clientCaseStatuses = []
  let clientCaseAppealTypes = []
  if (
    Array.isArray(clientCaseConstants?.client_case_timings) &&
    clientCaseConstants.client_case_timings.length > 0
  ) {
    clientCaseTimings.push({ value: '', display: 'Any' })
    for (let i in clientCaseConstants?.client_case_timings) {
      clientCaseTimings.push({
        value: clientCaseConstants.client_case_timings[i],
        display: clientCaseConstants.client_case_timings[i],
      })
    }
  }

  if (
    Array.isArray(clientCaseConstants?.client_case_statuses) &&
    clientCaseConstants.client_case_statuses.length > 0
  ) {
    clientCaseStatuses.push({ value: '', display: 'Any' })
    for (let i in clientCaseConstants?.client_case_statuses) {
      clientCaseStatuses.push({
        value: clientCaseConstants.client_case_statuses[i],
        display: clientCaseConstants.client_case_statuses[i],
      })
    }
  }

  if (
    Array.isArray(clientCaseConstants?.client_case_appeal_types) &&
    clientCaseConstants.client_case_appeal_types.length > 0
  ) {
    clientCaseAppealTypes.push({ value: '', display: 'Any' })
    for (let i in clientCaseConstants?.client_case_appeal_types) {
      clientCaseAppealTypes.push({
        value: clientCaseConstants.client_case_appeal_types[i],
        display: clientCaseConstants.client_case_appeal_types[i],
      })
    }
  }

  const renderingData = [
    {
      field: 'customer_name',
      display: 'Customer Name',
      type: 'text',
    },
    {
      field: 'customer_email',
      display: 'Customer Email',
      type: 'text',
    },
  ]
  if (clientCaseTimings.length > 0) {
    renderingData.push({
      field: 'client_case_timing',
      display: 'Timing',
      type: 'select',
      fieldType: 'select',
      options: clientCaseTimings,
    })
  }

  if (clientCaseStatuses.length > 0) {
    renderingData.push({
      field: 'client_case_status',
      display: 'Status',
      type: 'select',
      fieldType: 'select',
      options: clientCaseStatuses,
    })
  }

  if (clientCaseAppealTypes.length > 0) {
    renderingData.push({
      field: 'client_case_appeal_type',
      display: 'Appeal Type',
      type: 'select',
      fieldType: 'select',
      options: clientCaseAppealTypes,
    })
  }

  const footerActionProps = [
    {
      children: 'Search',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef?.current?.submitForm(),
      disabled: loading,
    },
  ]

  const error = formikRef?.current?.errors?.submit
  const [filterOpen, setFilterOpen] = useState(false)

  return (
    <>
      <Box>
        {error && (
          <Alert severity="warning">{formikRef?.current?.errors?.submit}</Alert>
        )}
        <Button
          variant="contained"
          sx={{ marginBottom: '5px' }}
          onClick={() => {
            setFilterOpen((filterOpen) => !filterOpen)
          }}
        >
          {filterOpen ? '[-]' : '[+]'}Filter
        </Button>
        <Collapse in={filterOpen}>
          <FormRenderer
            renderingData={renderingData}
            footerActionProps={footerActionProps}
            innerRef={formikRef}
            handleSubmit={handleSubmit}
          />
        </Collapse>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1" mb={2} mt={4}>
            Case Browser
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
            <DataTable
              title=""
              tableHeadStyling={{
                whiteSpace: 'nowrap',
              }}
              tableCellStyling={{
                whiteSpace: 'nowrap',
              }}
              tableRows={clientCaseDisplay || []}
              seeMoreState={hasMoreCases}
              seeMoreFunction={getNextPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default CaseBrowser
